import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="" />
    <div id="fbBoxLuny">
    <div style={{ maxWidth: `160px`, margin: '0 auto', textAlign: 'center'}}>
      <Link to="/">
      <img className="logo" src="LunyBy__.png" 
          alt="LunyBy, because lunatics need a good night's sleep too"
        />
      </Link>
      </div>
    </div>
    <div id="twBoxLuny"></div>
    <div id="bunnyLuny"></div>
    <div id="luny2"></div>
    <div style={{ maxWidth: `550px`, margin: '0 auto', textAlign: 'center'}}>

      <Image
          src="lunyby_logo_icon.png" 
          alt="LunyBy - because lunatics need a good night's sleep too"
          title="LunyBy - because lunatics need a good night's sleep too"
          isfluid="true"
        />
      </div>
    {/* <img src="https://lunyby.com/wp-content/themes/lunyby.com/img/LunyBy-Logo.gif" alt="LunyBy - because lunatics need a good night's sleep too" style={{marginTop:10 + 'px'}} /> */}
    <div id="belowLuny">because lunatics need a good night sleep too</div>
    <div id="copy">2011-2021 &copy;&nbsp; 
      <Link to="/">lunyby.com</Link>
    </div>
    
  </Layout>
)

export default IndexPage
